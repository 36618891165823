// Fonts
//
// This file defines the application fonts

@font-face {
    font-family: "Source Sans Pro";
    src: url($font-path + "source-sans-pro-v21-latin-regular.woff") format("woff"),
    url($font-path + "source-sans-pro-v21-latin-regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url($font-path + "source-sans-pro-v21-latin-600.woff") format("woff"),
    url($font-path + "source-sans-pro-v21-latin-600.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url($font-path + "source-sans-pro-v21-latin-700.woff") format("woff"),
    url($font-path + "source-sans-pro-v21-latin-700.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
