// Tables
//
// We have defined a custom set of table styles that simulate table designs but actually use divs and not tables.
// We wanted to keep the data displayed aligned within the grid system and also add some responsiveness to
// certain layouts. We should find a better/other solution at some point, that's why we treat this file as legacy.


.div-table {
    display: flex;
    flex-direction: column;

    // HACK #6339: Workaround to IE flexbox vertical-centering issues:
    // In IE `align-items: center` does only work if either a height is set (min-height is not enough)
    // or the flexbox is a direct child of a flexbox. This style transforms the parent elements of a
    // `.table-row` into flexboxes, if the `.table-row` is not a direct child of a `.div-table`
    div[ng-switch], kw-editable > kw-editable-viewer > ng-transclude { // NOSONAR scss:unknown-type-selector: 'ng-transclude' being addressed here, does not match our normal 'kw-.*' exclude pattern, but needed for this workaround
        display: flex;
        flex-direction: column;
    }

    // Individual table rows in several styles:
    // - header-row: column headers
    // - table-row:  ordinary row
    // - footer-row: footer row for totals etc.
    .header-row,
    .table-row,
    .footer-row {

        // Use flexbox layout with align-items=center for table-row content

        display: flex;
        align-items: center;

        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: normal;

        padding: $kw-div-table-row-vertical-padding 0;

        // Use some loops to get rid of partial selectors like [class*="col-"],
        // this increases the performance

        @each $breakpoint in map-keys($grid-breakpoints) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @for $i from 1 through $grid-columns {

                .col#{$infix}-#{$i} {
                    padding-left: ceil($kw-div-table-grid-gutter-width*0.5);
                    padding-right: floor($kw-div-table-grid-gutter-width*0.5);
                }

                // Override left and right special padding for first and last column divs

                .col#{$infix}-#{$i}:first-child {
                    padding-left: 0;
                }

                .col#{$infix}-#{$i}:last-child {
                    padding-right: 0;
                }

                // inside an inner, nested plain bootstrap row the offset correction has already been taken care of,
                // thus use standard offset now.

                .row .col#{$infix}-#{$i}:first-child {
                    padding-left: ceil($kw-div-table-grid-gutter-width*0.5);
                }

                .row .col#{$infix}-#{$i}:last-child {
                    padding-right: floor($kw-div-table-grid-gutter-width*0.5);
                }

                // make sure that an offset column also uses the correct gutter width

                .row .col#{$infix}-#{$i}.offset#{$infix}-#{$i}:first-child {
                    padding-right: floor($kw-div-table-grid-gutter-width*0.5);
                }

            }
        }

        // Override the gutter for nested rows
        .row {
            @include make-row($kw-div-table-grid-gutter-width);
        }

        // No bottom margin for controls inside table rows
        .form-group {
            margin-bottom: 0;
        }
    }

    .btn-toolbar {
        @extend .justify-content-end;
    }

    .header-row {

        border-bottom: 1px solid $list-group-border-color;
        align-items: flex-end;
        padding-bottom: $kw-div-table-header-bottom-padding;
        padding-top: 0;

        letter-spacing: $kw-div-table-header-letter-spacing;
        white-space: normal;
        font-size: $font-size-sm;
        color: $kw-gray;

        .row {
            align-items: flex-end;
        }

        .btn-toolbar {
            font-size: $font-size-base;
        }
    }

    .table-row {
        min-height: $kw-div-table-row-min-height;
        border-bottom: 1px solid $list-group-border-color;

        &.has-link:hover {
            background: $kw-gray-lightest;
            cursor: pointer;
        }

        div {
            overflow-x: hidden;
            text-overflow: ellipsis;

            .no-ellipsis {
                text-overflow: clip;
            }
        }

        .btn-toolbar {
            overflow: visible;
        }
    }

    .footer-row {
        font-weight: $font-weight-bold;
    }

    textarea {
        resize: vertical;
    }
}
