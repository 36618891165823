$kw-toaster-border-colors: () !default;
$kw-toaster-border-colors: map-merge((
    "success": $success,
    "danger": $danger,
    "info": $info,
    "warning": $warning)
, $kw-toaster-border-colors);

$kw-toaster-background-colors: () !default;
$kw-toaster-background-colors: map-merge((
    "success": $kw-success-bg,
    "danger": $kw-danger-bg,
    "info": $kw-info-bg,
    "warning": $kw-warning-bg
), $kw-toaster-background-colors);


.toast-wrapper {
    position: fixed;
    z-index: 999999;
    top: $spacer-md;
    right: $spacer-md;
}

.toast-container {
    position: relative;
    pointer-events: auto;
    width: 400px;

    &:not(:last-child) {
        margin-bottom: $spacer-sm;
    }

    @each $color, $value in $kw-toaster-border-colors {

        &.toast-#{$color} {
            background-color: map-get($kw-toaster-background-colors, $color);
            border: 1px solid $value;

            .toast-close-button {
                color: $value;
            }

            .fad {
                color: $value;
            }
        }
    }

    .fad {
        font-size: $kw-icon-md;
        padding-left: $spacer-md;
    }

    &.toast-warning .toast-close-button {
        color: darken(map-get($kw-toaster-border-colors, "warning"), 10%); // warning alerts need more contrast on the close icon
    }

    .toast-close-button {
        padding: 0 $spacer-sm;
        font-size: $kw-icon-md;
        font-weight: normal;
        text-shadow: none;
        background: transparent;
        border: none;
        align-self: baseline;
        position: absolute;
        right: 0;
    }

    .toast-body {
        font-size: $font-size-base;
        box-shadow: $box-shadow;
        display: flex;
        align-items: center;
        padding: 0;
        position: relative;

        &:hover {
            cursor: default;
        }
    }

    .toast-content {
        padding: $spacer-md $spacer-lg $spacer-md $spacer-md;
        align-self: center;
        flex-grow: 1;
        min-width: 0;
    }
}
