.popover {
    max-width: 100%;
    border-color: $border-color;
    border-radius: $popover-border-radius;

    &.bs-popover-start > .popover-arrow::before {
        border-left-color: $border-color;
    }

    &.bs-popover-end > .popover-arrow::before {
        border-right-color: $border-color;
    }
}

.delete-confirmation-popover {
    min-width: max-content;

    .popover-body {
        padding: 0;

        button {
            border: none;
        }

        button:not(:last-child) {
            border-right: 1px solid $border-color;
        }
    }
}
