kw-change-password {
    .title {
        @include spacer('mt-0');
        @include spacer('mb-200');
    }

    .form-row {
        @include make-row($grid-gutter-width);

        .password {
            @include make-col-ready();

            @include media-breakpoint-up(md) {
                @include make-col(12);
            }

            .kw-password-control {
                position: relative;
                display: inline-flex;
                width: 100%;

                input {
                    padding-right: $spacer-lg;
                }

                input::-ms-reveal { // NOSONAR: Disable MS browser’s native password reveal
                    display: none;
                }
            }

            .kw-password-reveal-icon {
                color: $kw-gray-light;
                position: absolute;
                right: 0;
                width: $spacer-lg;

                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }

    }

    .buttons {
        @include spacer('mt-200');
        text-align: end;

        .cancel-button {
            @include spacer('me-100');
        }
    }
}
