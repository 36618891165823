.autosize-textarea {
    overflow: hidden;
}

.autosize-textarea-animation {
    transition: all .5s ease;
}

.autosize-textarea-expand {
    cursor: row-resize;

    &:focus {
        cursor: text;
    }
}
