.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::after {
        min-height: inherit;
        font-size: 0;
        content: '';
    }

    &-primary, &-outline-primary, &-outline-secondary {

        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border-radius: $input-border-radius;
            transition: all .3s linear;
        }

        &:active::after {
            opacity: 1;
            transition: 0s;
        }
    }

    @each $color, $value in $theme-colors {

        &-#{$color}, &-outline-#{$color} {

            border-color: $value;
            $tmp: map-get($theme-colors-dark, #{$color});

            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled).active:focus,
            &:not(:disabled):not(.disabled):focus {
                color: color-contrast($tmp);
                border-color: $tmp;
                background-color: $tmp;
            }

            &:not(:disabled):not(.disabled):active:focus {
                border-color: $value;
                background-color: $value;
                box-shadow: inset 2px 3px 5px rgba(0, 0, 0, .15);
            }
        }
    }

    &-outline-secondary, &-light {

        color: $kw-gray;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled):focus {
            color: $kw-gray;
            background-color: $kw-gray-lighter;
        }

        &.disabled, &:disabled {
            color: $kw-gray-light;
            background-color: transparent;
        }
    }

    &-light {

        border-color: transparent;
        background-color: transparent;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled):focus {
            border-color: $kw-gray-lighter;
        }

        &.disabled, &:disabled {
            border-color: transparent;
            color: $kw-gray-light;
            background-color: transparent;
        }
    }

    &-warning {
        color: $white;
    }

    &-link, &-stripped {
        padding: .25rem .375rem;
        margin-left: -.375rem;
        line-height: 1;
        border-color: transparent;
    }

    &-link, &-stripped, &-close {

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active,
        &:focus {
            text-decoration: none;
            border-color: transparent;
            background-color: $kw-btn-link-hover-bg;
        }
    }

    &-stripped {
        color: $kw-gray;
        border-radius: 0;
        padding: 0;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
            background-color: transparent;
            color: $link-color;
        }

        .ng-fa-icon {
            line-height: 1rem;
        }
    }

    @each $color, $value in $theme-colors {

        &-stripped-#{$color} {
            color: $value;
            border-radius: 0;

            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active:focus {
                box-shadow: none;
                background-color: transparent;
                color: darken($value, 10%);
            }

            &:hover {
                color: $value;
            }
        }
    }

    &-outline-link {

        color: $link-color;
        border-color: $input-border-color;
        background-color: $white;

        &:not(:disabled):not(.disabled):hover {
            color: $link-hover-color;
            background-color: $kw-btn-link-hover-bg;
        }

        &:disabled {
            color: $btn-link-disabled-color;
            background-color: $white;
            border-color: $input-border-color;
        }
    }

    .fa, .fal, .far, .fas, .fa-light, .fa-regular, .fa-solid {
        color: inherit;
        align-items: baseline;

        &:first-child:not(:last-child) {
            margin-right: .5rem;
        }

        &:last-child:not(:first-child) {
            margin-left: .5rem;
        }
    }

    &.kw-expand-icon {
        @extend .btn-link;
        @extend .kw-icon-btn;
        &:focus,
        &:active {
            background-color: transparent;
        }
    }
}

.btn-toolbar {
    .btn {
        margin-right: $spacer-sm;
    }

    &.justify-content-end {
        .btn {
            margin-left: $spacer-sm;
            margin-right: 0;
        }
    }

    align-items: center;
}

.btn-row {
    display: flex;
    justify-content: $kw-btn-row-alignment;

    .btn {
        @if ($kw-btn-row-alignment == 'flex-end') {
            margin-left: $kw-btn-row-spacer;
            margin-right: 0;
        } @else {
            margin-left: 0;
            margin-right: $kw-btn-row-spacer;
        }
    }
}

.kw-icon-btn {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: .5rem;
}
