@import "@ng-select/ng-select/scss/mixins";

// TODO: #12289 style and test:
// - grouped selects
// - nested options
// - checkbox selects
// - disabled options
// - replace leftover ng-select variables with bootstrap variables

$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-disabled: $kw-gray !default;

.ng-select {

    .ng-select-container {
        @extend .form-control;

        .ng-value-container {
            align-items: center;
            .ng-placeholder {
                color: $input-placeholder-color;
            }
        }
    }

    .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-clear-wrapper {
        display: flex;
        justify-content: center;
        align-content: center;
        color: $kw-gray;

        .ng-clear {
            padding-top: 1px;
            font-size: $font-size-sm;
        }

        &:hover .ng-clear {
            color: $input-color;
        }
    }

    .ng-spinner-zone {
        padding: 5px 5px 0 0;
    }

    .ng-arrow-wrapper {
        width: $spacer-md;
        display: flex;
        align-items: center;
        flex: 0;

        .ng-arrow {
            @extend .far;
            @extend .fa-angle-down;
            height: auto !important;
            width: auto !important;
            font-size: $font-size-sm;
            color: $kw-gray;
        }

        &:hover {
            .ng-arrow {
                color: $kw-gray;
            }
        }
    }

    &.ng-select-single {
        .ng-select-container {
            .ng-value-container {
                .ng-input {
                    padding: 0 $input-padding-x;
                    padding-right: 3rem;
                }
            }
        }
    }

    &.ng-select-multiple {

        &.ng-select-disabled {
            > .ng-select-container .ng-value-container .ng-value {
                background-color: $input-disabled-bg;
                border: 1px solid lighten($input-border-color, 10);
                .ng-value-label {
                    padding: 0 $spacer-xs;
                }
            }
        }

        .ng-select-container {
            min-height: calc(1.4em + 0.75rem + 2px);
            height: auto;
            padding: 0.175rem .375rem;

            .ng-value-container {

                .ng-value {
                    font-size: $font-size-base;
                    background-color: $light;
                    border-radius: $input-border-radius/2;
                    margin-top: .125rem;
                    margin-right: $spacer-sm;
                    margin-bottom: .125rem;
                    display: flex;

                    &.ng-value-disabled {
                        background-color: $input-disabled-bg;
                        .ng-value-label {
                            padding-left: 5px;
                        }
                    }

                    .ng-value-label {
                        order: 1;
                        display: inline-block;
                        padding: 1px 1px 1px $spacer-xs;
                    }

                    .ng-value-icon {
                        order: 2;
                        display: inline-block;
                        padding: 1px $spacer-xs;
                        color: $link-color;

                        &:hover {
                            color: $body-color;
                        }
                    }
                }

                .ng-input {
                    > input {
                        color: $input-color;
                        height: auto;
                    }
                }
            }
        }
    }

    &.ng-select-opened {
        > .ng-select-container {
            .ng-arrow {
                @extend .fa-angle-up;
            }
        }

        &.ng-select-top {
            > .ng-select-container {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &.ng-select-right {
            > .ng-select-container {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.ng-select-bottom {
            > .ng-select-container {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.ng-select-left {
            > .ng-select-container {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            color: $input-focus-color;
            background-color: $input-focus-bg;
            border-color: $input-focus-border-color;
            outline: 0;
            @if $enable-shadows {
                @include box-shadow($input-box-shadow, $input-focus-box-shadow);
            } @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: $input-focus-box-shadow;
            }
        }
    }

    &.ng-select-disabled {
        > .ng-select-container {
            background-color: $input-disabled-bg;
        }
    }
}

.has-error .ng-select {
    &:not(.ng-select-opened) > .ng-select-container {
        border-color: darken($form-feedback-invalid-color, 10%);
    }
}

.input-group :first-child .ng-select {
    > .ng-select-container {
        @include border-end-radius(0)
    }

    > .ng-dropdown-panel {
        width: auto;
    }
}

.ng-dropdown-panel {
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    box-shadow: $box-shadow-sm;

    &.ng-select-top {
        bottom: 100%;
        border-top-right-radius: $input-border-radius;
        border-top-left-radius: $input-border-radius;
        border-bottom-color: lighten($input-border-color, 10);
        margin-bottom: -1px;
        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: $input-border-radius;
                    border-top-left-radius: $input-border-radius;
                }
            }
        }
    }

    &.ng-select-right {
        left: 100%;
        top: 0;
        border-top-right-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
        border-bottom-color: lighten($input-border-color, 10);
        margin-bottom: -1px;
        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: $input-border-radius;
                }
            }
        }
    }

    &.ng-select-bottom {
        top: 100%;
        border-bottom-right-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
        border-top-color: lighten($input-border-color, 10);
        margin-top: -1px;
        .ng-dropdown-panel-items {
            .ng-option {
                &:last-child {
                    border-bottom-right-radius: $input-border-radius;
                    border-bottom-left-radius: $input-border-radius;
                }
            }
        }
    }

    &.ng-select-left {
        left: -100%;
        top: 0;
        border-top-left-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
        border-bottom-color: lighten($input-border-color, 10);
        margin-bottom: -1px;
        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-left-radius: $input-border-radius;
                }
            }
        }
    }

    .ng-dropdown-header {
        border-bottom: 1px solid $input-border-color;
        padding: $spacer-sm;
    }

    .ng-dropdown-footer {
        border-top: 1px solid $input-border-color;
        padding: $spacer-sm;
    }

    .ng-dropdown-panel-items {

        .ng-optgroup {
            user-select: none;
            padding: $spacer-sm;
            font-weight: 500;
            color: $ng-select-dropdown-optgroup-text;
            cursor: pointer;

            &.ng-option-disabled {
                cursor: default;
            }

            &.ng-option-marked {
                background-color: $kw-gray-lightest;
            }

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                color: $ng-select-dropdown-optgroup-marked;
                background-color: $kw-gray-lighter;
                font-weight: 600;
            }
        }

        .ng-option {
            background-color: $input-bg;
            color: $input-color;
            padding: $spacer-sm;

            &.ng-option-selected, &.ng-option-selected.ng-option-marked {
                color: $input-color;
                background-color: $kw-gray-lighter;
                .ng-option-label {
                    font-weight: 600;
                }
            }

            &.ng-option-marked {
                background-color: $kw-gray-lightest;
                color: $input-color;
            }

            &.ng-option-disabled {
                color: $ng-select-dropdown-option-disabled;
            }

            &.ng-option-child {
                padding-left: 22px;
            }

            .ng-tag-label {
                font-size: 80%;
                font-weight: 400;
                padding-right: 5px;
            }
        }
    }
}
