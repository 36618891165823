@use "sass:color";
@use "sass:map";
@use "sass:math";

//--------------------------------------------------------------------------
// Override Bootstrap variables
// Custom variables should start with $kw-*
//--------------------------------------------------------------------------

// Colors
//
// Control the default colors of most Bootstrap elements by modifying these variables
$enable-negative-margins: true !default;
$black: #000000 !default;
$body-color: #262626 !default;
$kw-gray: #737373 !default;
$kw-gray-light: #A0A0A0 !default;
$kw-gray-lighter: #E1E1E1 !default;
$kw-gray-lightest: #F2F2F2 !default;
$white: #ffffff !default;

$primary: #006EEF !default;
$kw-primary-bg: #D6E9FF !default;
$secondary: $kw-gray-lighter !default;
$success: #0EAB24 !default;
$kw-success-bg: #B6F2B1 !default;
$info: $kw-gray !default;
$kw-info-bg: $kw-gray-lightest !default;
$warning: #FF8900 !default;
$kw-warning-bg: #FDDEB9 !default;
$danger: #E72323 !default;
$kw-danger-bg: #FFD1D1 !default;
$light: $kw-gray-lighter !default;
$dark: $kw-gray !default;

// add a darker color for hover and focused states use for buttons and other form controls
$theme-colors-dark: () !default;
$theme-colors-dark: map.merge(
        (
            "primary": color.adjust($primary, $lightness: -10%),
            "secondary": color.adjust($secondary, $lightness: -10%),
            "success": color.adjust($success, $lightness: -10%),
            "info": color.adjust($info, $lightness: -10%),
            "warning": color.adjust($warning, $lightness: -10%),
            "danger": color.adjust($danger, $lightness: -10%),
            "light": color.adjust($light, $lightness: -10%),
            "dark": color.adjust($dark, $lightness: -10%)
        ),
        $theme-colors-dark
);

// add a lighter color for light backgrounds on badges
$theme-colors-light: () !default;
$theme-colors-light: map.merge(
        (
            "primary": $kw-primary-bg,
            "secondary": $kw-gray-lighter,
            "success": $kw-success-bg,
            "danger": $kw-danger-bg,
            "warning": $kw-warning-bg,
            "info": $kw-info-bg
        ),
        $theme-colors-light
);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $body-color !default;
$color-contrast-light: $white !default;

$kw-application-bg: $kw-gray-lightest !default;
$kw-navbar-bg: $body-color !default;
$kw-navbar-open-bg: $black !default;
$kw-navbar-active-bg: #474747 !default;
$kw-brand-color: color.adjust($primary, $lightness: 10%, $saturation: -60%) !default;
$kw-brand-color-light: #A5C7EF;

$border-color: $kw-gray-lighter !default;

$input-color: $body-color !default;
$input-placeholder-color: $kw-gray-light !default;
$input-border-color: $border-color !default;
$input-focus-color: $body-color !default;
$input-focus-border-color: $primary !default;
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;

// Options

$enable-caret: false !default;
$enable-gradients: false !default;
$enable-shadows: true !default;
$enable-validation-icons: false !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
//
// PX to REM reference
//
//  1px = .0625rem
//  2px = .125rem
//  3px = .1875rem
//  4px = .25rem
//  8px = .5rem
// 10px = .625rem
// 12px = .75rem
// 16px = 1rem

$spacer: 1rem !default;

$spacers: (
    0: 0, // 0px
    25: .25rem, // 4px
    50: .5rem, // 8px
    75: .75rem, // 12px
    100: 1rem, // 16px
    125: 1.25rem, // 20px
    150: 1.5rem, // 24px
    200: 2rem, // 32px
    300: 3rem, // 48px
    400: 4rem // 64px
) !default;
$spacers: map.merge((
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 2),
    5: ($spacer * 4)
), $spacers);

$spacer-xs: map.get($spacers, 25) !default;
$spacer-sm: map.get($spacers, 50) !default;
$spacer-md: map.get($spacers, 100) !default;
$spacer-lg: map.get($spacers, 200) !default;
$spacer-xl: map.get($spacers, 400) !default;

$kw-heading1-margin-top: $spacer-xl !default;
$kw-heading1-margin-bottom: $spacer-md !default;
$kw-heading2-margin-top: $spacer-lg !default;
$kw-heading2-margin-bottom: $spacer-sm !default;
$kw-heading3-margin-top: $spacer-md !default;
$kw-heading3-margin-bottom: 0 !default;
$kw-heading4-margin-top: $spacer-sm !default;
$kw-heading4-margin-bottom: 0 !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-hover-color: color.adjust($primary, $lightness: -10%) !default;
$link-decoration: none !default;

// Grid columns
$grid-gutter-width: 1rem !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;

$border-radius-sm: .1875rem !default;
$border-radius: .25rem !default;
$border-radius-lg: .5rem !default;

$box-shadow-sm: 0 1px 2px rgba($black, 0.1) !default;
$box-shadow: 0 .5rem 1rem rgba($black, 0.1) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.1) !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// Overwrite this variable '@kwsoft/styles/fonts' to point to the node_modules to resolve
// relative url issues when relative path is not found
$font-path: "../fonts/" !default;

$font-family-sans-serif: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default;
$font-size-sm: .8125rem !default;

$font-weight-normal: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.4 !default;

$text-muted: $kw-gray-light !default;

$small-font-size: $font-size-sm !default;

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: $font-size-base * 1 !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-bold !default;
$headings-line-height: 1.2 !default;
$headings-color: $body-color !default;

// Buttons

$btn-padding-x: 1rem !default;
$btn-padding-y: .375rem !default;

$btn-box-shadow: none !default;
$btn-active-box-shadow: none !default;
$btn-focus-box-shadow: none !default;
$btn-disabled-opacity: .5 !default;
$btn-link-disabled-color: $primary !default;
$btn-close-color: $link-color !default;
$kw-btn-link-hover-bg: color.adjust($kw-gray-light, $alpha: -.8) !default;

$kw-btn-row-alignment: flex-end !default;
$kw-btn-row-spacer: $spacer-md !default;

// Close - button

$btn-close-color: $link-color !default;
$btn-close-opacity: 1 !default;
$btn-close-hover-opacity: 1 !default;
$btn-close-focus-shadow: none !default;
$btn-close-padding-x: $spacer-sm !default;
$btn-close-padding-y: $spacer-sm !default;

// Forms

$input-line-height: 1.4 !default;
$input-padding-x: .375rem !default;
$input-padding-y: .375rem !default;

$input-disabled-bg: $kw-gray-lighter !default;

$input-shadow-level: 7 !default;
$kw-input-btn-focus-blur-width: .2rem !default;
$input-btn-focus-width: .2rem !default;
$input-btn-focus-box-shadow: none !default;

$form-check-label-cursor: pointer !default;
$form-check-min-height: auto !default;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow !default;
$form-check-input-focus-border: none !default;
$form-switch-color: $input-border-color !default;
$form-switch-focus-color: $form-switch-color !default;

$form-feedback-invalid-color: $danger !default;
$form-validation-states: (
    "invalid": (
        "color": $form-feedback-invalid-color,
        "icon": null
    )
) !default;

$label-margin-bottom: 0 !default;

// Cards

$card-spacer-x: $spacer-md !default;
$card-spacer-y: $spacer-md !default;
$card-title-spacer-y: $spacer !default;
$card-border-width: 0 !default;
$card-box-shadow: $box-shadow-sm !default;
$card-cap-bg: none !default;

// Navs

$nav-link-padding-y: $spacer-md !default;
$nav-tabs-border-color: $border-color !default;
$nav-tabs-link-hover-border-color: $kw-gray-lightest $kw-gray-lightest $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $body-color !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;
$nav-underline-link-active-color: $body-color !default;
$kw-nav-underline-active-border-width: .125rem !default;
$kw-nav-underline-tab-height: 2.5rem !default;

// Navbars

$navbar-padding-y: 0 !default;
$navbar-padding-x: 0 !default;
$navbar-brand-padding-y: 0 !default;
$navbar-nav-link-padding-x: $spacer-lg !default;
$navbar-nav-link-padding-y: $spacer-md !default;

$navbar-dark-brand-color: $white !default;
$navbar-dark-brand-hover-color: $white !default;
$navbar-dark-color: $white !default;
$navbar-dark-hover-color: $white !default;
$navbar-dark-active-color: $white !default;

$navbar-light-brand-color: $kw-brand-color !default;
$navbar-light-brand-hover-color: $kw-brand-color !default;
$navbar-light-color: $primary !default;
$navbar-light-hover-color: $body-color !default;
$navbar-light-active-color: $body-color !default;

// Pagination

$pagination-border-color: $border-color !default;
$pagination-hover-bg: $kw-gray-lightest !default;
$pagination-hover-border-color: $border-color !default;
$pagination-disabled-color: $kw-gray-lighter !default;
$pagination-disabled-border-color: $border-color !default;

// Dropdown

$dropdown-border-radius: $border-radius-sm !default;
$dropdown-box-shadow: $box-shadow !default;
$dropdown-min-width: 100% !default;
$dropdown-spacer: 0 !default;
$dropdown-bg: $white !default;
$dropdown-border-color: $kw-gray-lighter !default;
$dropdown-padding-x: $spacer-sm !default;
$dropdown-padding-y: $spacer-sm !default;

$dropdown-header-padding: $spacer-md $spacer-md $spacer-xs $spacer-md !default;
$dropdown-header-color: $body-color !default;

$dropdown-inner-border-radius: $border-radius-sm !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-bg: $kw-gray-lighter !default;
$dropdown-link-hover-color: $body-color !default;
$dropdown-link-active-bg: $primary !default;
$dropdown-link-active-color: $white !default;
$dropdown-item-padding-x: $spacer-md !default;
$dropdown-item-padding-y: $spacer-sm !default;

// Modals

$modal-content-border-width: 0 !default;

// Toasts
//
// Define toast padding, shadow and width.

$toast-padding-y: $spacer-lg !default;
$toast-padding-x: $spacer-lg !default;
$toast-box-shadow: $box-shadow-lg !default;

$kw-toast-width-factors: () !default;
$kw-toast-width-factors: (
    sm: .8,
    md: .7,
    lg: .6,
    xl: .5
) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer-md !default;
$alert-padding-x: $spacer-md !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: 0 !default;

$alert-bg-level: -10 !default;
$alert-border-level: -8 !default;
$alert-color-level: 6 !default;

$kw-alert-bg-colors: () !default;
$kw-alert-bg-colors: (
    "success": $kw-success-bg,
    "info": $kw-info-bg,
    "warning": $kw-warning-bg,
    "error": $kw-danger-bg,
) !default;

$kw-alert-close-icons: () !default;
$kw-alert-close-icons: (
    "success": url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogIzNmYWY2YzsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9InRpbWVzIiBjbGFzcz0iY2xzLTEiIGQ9Ik05LjcsMTA0bDUuMTI4LTUuMTI4LDEuMDU4LTEuMDU4YS40LjQsMCwwLDAsMC0uNTY2bC0xLjEzMi0xLjEzMmEuNC40LDAsMCwwLS41NjYsMEw4LDEwMi4zLDEuODE1LDk2LjExN2EuNC40LDAsMCwwLS41NjYsMEwuMTE3LDk3LjI0OWEuNC40LDAsMCwwLDAsLjU2Nkw2LjMsMTA0LC4xMTcsMTEwLjE4NmEuNC40LDAsMCwwLDAsLjU2NmwxLjEzMiwxLjEzMmEuNC40LDAsMCwwLC41NjYsMEw4LDEwNS43bDUuMTI4LDUuMTI4LDEuMDU4LDEuMDU4YS40LjQsMCwwLDAsLjU2NiwwbDEuMTMyLTEuMTMyYS40LjQsMCwwLDAsMC0uNTY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtOTYpIi8+Cjwvc3ZnPgo="),
    "info": url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogIzAwODVjYzsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9InRpbWVzIiBjbGFzcz0iY2xzLTEiIGQ9Ik05LjcsMTA0bDUuMTI4LTUuMTI4LDEuMDU4LTEuMDU4YS40LjQsMCwwLDAsMC0uNTY2bC0xLjEzMi0xLjEzMmEuNC40LDAsMCwwLS41NjYsMEw4LDEwMi4zLDEuODE1LDk2LjExN2EuNC40LDAsMCwwLS41NjYsMEwuMTE3LDk3LjI0OWEuNC40LDAsMCwwLDAsLjU2Nkw2LjMsMTA0LC4xMTcsMTEwLjE4NmEuNC40LDAsMCwwLDAsLjU2NmwxLjEzMiwxLjEzMmEuNC40LDAsMCwwLC41NjYsMEw4LDEwNS43bDUuMTI4LDUuMTI4LDEuMDU4LDEuMDU4YS40LjQsMCwwLDAsLjU2NiwwbDEuMTMyLTEuMTMyYS40LjQsMCwwLDAsMC0uNTY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtOTYpIi8+Cjwvc3ZnPgo="),
    "error": url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2RkMzc0MjsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9InRpbWVzIiBjbGFzcz0iY2xzLTEiIGQ9Ik05LjcsMTA0bDUuMTI4LTUuMTI4LDEuMDU4LTEuMDU4YS40LjQsMCwwLDAsMC0uNTY2bC0xLjEzMi0xLjEzMmEuNC40LDAsMCwwLS41NjYsMEw4LDEwMi4zLDEuODE1LDk2LjExN2EuNC40LDAsMCwwLS41NjYsMEwuMTE3LDk3LjI0OWEuNC40LDAsMCwwLDAsLjU2Nkw2LjMsMTA0LC4xMTcsMTEwLjE4NmEuNC40LDAsMCwwLDAsLjU2NmwxLjEzMiwxLjEzMmEuNC40LDAsMCwwLC41NjYsMEw4LDEwNS43bDUuMTI4LDUuMTI4LDEuMDU4LDEuMDU4YS40LjQsMCwwLDAsLjU2NiwwbDEuMTMyLTEuMTMyYS40LjQsMCwwLDAsMC0uNTY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtOTYpIi8+Cjwvc3ZnPgo="),
    "warning": url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogI2U2OTMwMDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9InRpbWVzIiBjbGFzcz0iY2xzLTEiIGQ9Ik05LjcsMTA0bDUuMTI4LTUuMTI4LDEuMDU4LTEuMDU4YS40LjQsMCwwLDAsMC0uNTY2bC0xLjEzMi0xLjEzMmEuNC40LDAsMCwwLS41NjYsMEw4LDEwMi4zLDEuODE1LDk2LjExN2EuNC40LDAsMCwwLS41NjYsMEwuMTE3LDk3LjI0OWEuNC40LDAsMCwwLDAsLjU2Nkw2LjMsMTA0LC4xMTcsMTEwLjE4NmEuNC40LDAsMCwwLDAsLjU2NmwxLjEzMiwxLjEzMmEuNC40LDAsMCwwLC41NjYsMEw4LDEwNS43bDUuMTI4LDUuMTI4LDEuMDU4LDEuMDU4YS40LjQsMCwwLDAsLjU2NiwwbDEuMTMyLTEuMTMyYS40LjQsMCwwLDAsMC0uNTY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAtOTYpIi8+Cjwvc3ZnPgo=")
) !default;

$kw-alert-width-factors: () !default;
$kw-alert-width-factors: (
    sm: .8,
    md: .7,
    lg: .6,
    xl: .5
) !default;

$kw-alert-color-interval: 8% !default;
$kw-alert-box-shadow: $toast-box-shadow !default;
$kw-alert-icon-size: 2rem !default;

// Popover
$popover-border-radius: $border-radius !default;

// Badges
$badge-font-size: $font-size-sm !default;
$badge-font-weight: $font-weight-normal !default;

$badge-padding-x: .375rem !default;
$badge-padding-y: .25rem !default;

// Progress Bar

$progress-height: .5rem !default;
$progress-bg: $kw-gray-lighter !default;
$progress-border-radius: 0 !default;
$progress-box-shadow: none !default;

$kw-progress-bar-top-margin: -1px !default;
$kw-progress-bar-bottom-margin: 0 !default;
$kw-progress-bar-inline-height: 5px !default;

// Icons

$kw-icon-xxs: .75rem !default;
$kw-icon-xs: 1rem !default;
$kw-icon-sm: 1.25rem !default;
$kw-icon-md: 1.6rem !default;
$kw-icon-lg: 1.9rem !default;
$kw-icon-xl: 5rem !default;

// Lists

$list-spacer-left: 1.5rem !default;
$list-spacer-inner: 1.5rem !default;
$list-group-border-color: $border-color !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: $spacer-sm !default;
$table-cell-padding-sm: $spacer-sm !default;

$table-bg: transparent !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-color: $kw-gray !default;

// DIV Tables

$kw-div-table-row-vertical-padding: $spacer-sm !default;
$kw-div-table-grouped-rows-offset: $spacer-md !default;
$kw-div-table-header-bottom-padding: $spacer-xs !default;
$kw-div-table-grid-gutter-width: 12px !default;
$kw-div-table-row-min-height: 3.25rem !default;
$kw-div-table-header-letter-spacing: .3px !default;

// Advice comments

$advice-comment-editor-highlighted: $kw-info-bg !default;
