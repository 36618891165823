.kw-issue-indicator {

    display: flex;
    align-items: center;
}

kw-issue-tracker {
    &:first-of-type h1 {
        margin-top: 0;
    }

    &:not(:first-of-type) h1 {
        margin-top: $spacer-lg;
    }
}

.kw-issues-container {
    .alert {
        margin: $spacer-sm 0;
        padding: $spacer-md;
    }
}

.kw-issue-text {
    white-space: pre-line;
}

kw-issue {
    .alert .btn-link {
        color: $link-color;
    }
}
