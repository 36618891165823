kw-date-picker-input {
    .btn-outline-link {
        @include spacer('py-0');

        .calendar-icon {
            display: flex; // fixes vertical alignment
        }
    }
}

ngb-datepicker {
    .btn-light {
        color: $body-color;

        &:not(:disabled):not(.disabled):hover {
            color: $body-color;
            background-color: $kw-gray-lighter;
        }
    }

    .text-muted.outside {
        opacity: 1;
    }

    &.dropdown-menu {
        min-width: 0;
    }

    .ngb-dp-header {
        background-color: $kw-gray-lightest;
        border-radius: 0;
    }

    .ngb-dp-weekdays {
        background-color: $kw-gray-lightest;
        border-bottom-color: $border-color;
    }

    .ngb-dp-weekday {
        color: $kw-gray;
        font-style: normal;
    }
}
