//
// Textual form controls
//

@import "forms/form-check";

.form-control {
    min-height: $input-height;

    &:disabled, &[readonly] {
        @include box-shadow(none);
        border-color: $input-disabled-bg;
        background-color: $input-disabled-bg;
    }

    &.is-valid:focus,
    &.focus.is-valid {
        border-color: $input-border-color;
        box-shadow: none;
    }

    &.is-invalid:focus,
    &.focus.is-invalid {
        border-color: darken($form-feedback-invalid-color, 10%);
        box-shadow: none;
    }
}

.form-group {
    margin-bottom: 1rem;
    label {
        margin-bottom: $spacer-xs;
    }
}

.col-form-label {
    font-size: $font-size-base;
}

// MS Edge fix: suppress the automatic [x] it adds for clearing input fields
::-ms-clear {
    display: none;
}
