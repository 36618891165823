//
// Navigation Bars
//

.navbar-brand {
    display: flex;
    font-size: $h2-font-size;
    margin-right: $spacer-md;
    align-items: center;

    small {
        margin-top: $spacer-sm;
        margin-left: $spacer-xs;
        font-size: .75rem;
    }

    .navbar-text {
        font-size: $h2-font-size;
        margin-left: $spacer-md;
        padding: 0;
    }
}

.navbar-nav {

    .nav-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .nav-link {
            display: flex;
            flex-direction: row;
            align-items: center;

            .close {
                display: flex;
                margin: (-$spacer-xs) 0 (-$spacer-xs) $spacer-xs;
                padding: $spacer-xs;
                font-size: $h3-font-size;
                text-shadow: none;
                opacity: 1;
                color: $kw-gray-lighter;

                &:hover {
                    color: $white;
                }
            }
        }
    }

    .kw-nav-arrow {
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid $kw-navbar-bg;
        margin-right: -12px;
        z-index: 1;
    }
}

.navbar-underlined {
    .nav-item .nav-link {
        @include spacer("pb-75");
        border-bottom: 4px solid transparent;

        &.active {
            border-bottom: 4px solid $primary;
        }
    }
}

.navbar-light {
    background: $white;

    .navbar-text {
        color: $body-color;
    }
}

.navbar-dark {
    background: $kw-navbar-bg;

    .navbar-nav .nav-item {

        &:active, &:visited, &:hover {
            background-color: $kw-navbar-open-bg;
            .kw-nav-arrow {
                border-left-color: $kw-navbar-open-bg;
            }
        }

        &.show {
            &, &:active, &:visited, &:hover {
                background-color: $kw-navbar-open-bg;
            }
        }

        &.active {
            &, &:active, &:visited {
                background-color: $kw-navbar-active-bg;
                .kw-nav-arrow {
                    border-left-color: $kw-navbar-active-bg;
                }
            }
            &.show, &:hover, &:focus {
                background-color: $kw-navbar-open-bg;
                .kw-nav-arrow {
                    border-left-color: $kw-navbar-open-bg;
                }
            }
        }

        .fal {
            color: inherit;
        }
    }
}

.nav-link.dropdown-toggle {

    .fa-chevron-down, .fa-angle-down {
        top: 0;
        color: inherit;
        padding-left: $spacer-xs;
        padding-right: 0;
        font-size: $kw-icon-xxs;
    }

    &::after {
        display: none;
    }
}

// This is used as the vertical navigation bar in the administration console

.nav-pills {

    .nav-item {
        border-bottom: 1px solid $white;

        &:first-child {
            border-top: 1px solid $white;
        }

        .nav-link {
            padding-left: 0;
            padding-right: 0;

            &.active {
                color: $body-color;
                background-color: transparent;
                font-weight: $font-weight-bold;

                .text-bg-light {
                    color: $body-color !important; // NOSONAR, required to overwrite default badge background colors
                }
            }

            .text-bg-light {
                color: $primary !important; // NOSONAR, required to overwrite default badge colors
                background-color: $white !important; // NOSONAR, required to overwrite default badge background colors
                margin-left: $spacer-md;
            }
        }
    }
}
