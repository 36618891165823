.login-modal {

    .modal-dialog {
        width: 100vw;
        max-width: 100vw;
        margin: 0;
    }

    .modal-content {
        padding: 0;
        background-image: url('../assets/BG_login.jpg');
        background-size: cover;
        border-radius: 0;
    }

    .error-message {
        @include spacer('mb-100');
        font-size: $font-size-sm;
        color: $danger;
    }

    kw-login-form {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            @include spacer('mb-200');
            height: 40px;
            background-image: url('../assets/logo.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .card {
            width: 296px;
        }

        .button {
            @include spacer('mt-200');
            text-align: center;
        }

    }
}
