
// Margin, Padding
//
// Defines margin and padding classes for all spacers
// .m-100 translates to: margin: 1rem;
// .my-50 translates to: margin-top: .5rem; margin-bottom: .5rem;
// .me-25 translates to: margin-right: .25rem;

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }
        .#{$abbrev}e-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}s-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}

// Some special margin utils
.m-auto { margin: auto !important; }
.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.me-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ms-auto,
.mx-auto {
    margin-left: auto !important;
}
