@import "functions";

@mixin spacer($shorthand) {

    $type: "";
    $direction: "";
    $size: "";

    @each $prop, $abbrev in (margin: m, padding: p) {
        @if str_index($shorthand, $abbrev) {
            $type: $prop;
        }
    }

    @each $dir, $dir_short in (x-axis: x, y-axis: y, top: t, right: e, bottom: b, left: s) {
        @if str_index($shorthand, $dir_short) {
            @if not ($dir_short == x) and not ($dir_short == y) {
                $direction: "-" + $dir;
            } @else if ($dir_short == y) {
                $direction: (-top, -bottom);
            } @else {
                $direction: (-left, -right);
            }
        }
    }

    @if str_index($shorthand, "-") {
        $size: to-number(str_slice($shorthand, str_index($shorthand, "-")+1));
    }

    @if map_has_key($spacers, $size) {
        @each $way in $direction {
            // @debug $type + $way + ": " + map_get($spacers, $size) + ";";
            #{$type}#{$way}: map_get($spacers, $size);
        }
    }
}
