// Modal
//
// This file contains all custom modal related style definitions

$modal-xxl: 1400px !default;

.modal-content {
    padding: $spacer-md;
}

.modal-header {
    h1 {
        margin: 0;
        width: 100%;
    }
}

.modal-fullscreen-xxl-down, .modal-fullscreen {
    height: auto;
}

@include media-breakpoint-up(lg) {
    .modal-xxl {
        --#{$prefix}modal-width: #{$modal-lg};
    }
}

@include media-breakpoint-up(xl) {
    .modal-xxl {
        --#{$prefix}modal-width: #{$modal-xl};
    }
}

@include media-breakpoint-up(xxl) {
    .modal-xxl {
        --#{$prefix}modal-width: #{$modal-xxl};
    }
}
