//--------------------------------------------------------------------------
// Override Bootstrap and kw-styles variables:
// all variables defined with '!default' in kw-styles must be defined before importing 'variables' from there
// Custom variables should start with $kw-*
//--------------------------------------------------------------------------

$kw-heading1-margin-top: 0;
$kw-heading1-margin-bottom: 0;
$kw-heading2-margin-top: 0;
$kw-heading2-margin-bottom: 0;
$kw-heading3-margin-top: 0;
$kw-heading3-margin-bottom: 0;
$kw-heading4-margin-top: 0;
$kw-heading4-margin-bottom: 0;

@import "@kwsoft/styles/scss/variables";

//--------------------------------------------------------------------------
// Custom variables only used in branding can be added below
// Name should start with bni-
//--------------------------------------------------------------------------
