// Tables
//
// This file contains all custom table related style definitions

.table {

    h1, h2, h3, h4 {
        margin: 0;
    }

    thead th {
        border-top: none;
        border-bottom-width: $table-border-width;
        font-weight: $font-weight-normal;
        font-size: $font-size-sm;
        color: $kw-gray;
        padding: 0 .5rem .25rem;
    }

     th {
            color: $table-head-color;
            font-weight: $font-weight-normal;
        }

    tbody tr:last-child {
        border-bottom: $table-border-width solid $table-border-color;
    }

    tbody tr:first-child {
        border-top: $table-border-width solid $table-border-color;
    }

    .col-action {
        text-align: right;
        padding-right: 0;
    }

    tr:not(.text-muted) {
        small {
            color: $kw-gray;
        }
    }

    .selectable-entry {
        cursor: pointer;

        &:hover td, &:focus td {
            background-color: $kw-gray-lightest;
            outline: none;
        }
    }

    tfoot td {
        font-weight: $font-weight-bold;
        border-top: none;
        border-bottom: none;
        padding-bottom: 0;
    }
}
