@import 'variables';

// Tooltips
//
// Contains all customization and extension of bootstrap base tooltip styles and features

.tooltip-inner {
    box-shadow: $dropdown-box-shadow;
    white-space: pre-wrap;
}

.kw-tooltip-heading {
    display: flex;
    align-items: center;
}

.kw-tooltip {
    font-size: $font-size-sm;
    color: $kw-gray;
    margin-left: $spacer-xs;
}

.fit-content .tooltip-inner {
    max-width: none;
}

// aligns the text of a tooltip left
.tooltip.left-aligned .tooltip-inner {
    text-align: left;
}
