.accordion.card-accordion .accordion-item {
    &:first-of-type {
        margin-top: 1rem;
    }
    .accordion-header {
        align-items: flex-start;

        .row {
            align-items: baseline;
        }
    }
}
