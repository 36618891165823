$wf-step-img-padding: $spacer-sm;

// Overall layout structure using bootstrap fluid container with a max-width for very large screens (>1360px)
// this allows toggling the right-hand "complementary" panel without limiting the main content width too much
// the same properties are set on the "complementary" panel as well, as for smaller screens this is floated to the top above the main content
.kw-workflow-main, .kw-workflow-complementary {
    max-width: map-get($container-max-widths, "xxl");
}

// if extra space is still left, distribute it around the main content section (but keep the complementary info panel on the right)
.kw-workflow-main {
    margin-left: auto;
    margin-right: auto;
}

// style for the outer workflow panel, containing the workflow navigation on top and the actual current workflow
// state content below that. By default this outer panel uses an ui-bootstrap 'card' class

.kw-workflow-panel {
    // define styles for main linkbox dropdown (must be ABOVE first heading)
    .linkbox-workflow-step {
        position: absolute;
        top: 2rem;
        right: 0;
        margin: 0;
    }
}

kw-workflow-navigation,
.kw-workflow-navigation {
    .workflow-step.active .btn {
        background-color: $kw-gray-lightest;
    }

    .workflow-step {
        // those make sure all items are grown equally, flex-basis auto will distribute them across the whole
        // width, spaced according to the item's width (so larger items will get a bit more space)
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        // content of list-item is rendered using an inner flexbox layout so we can layout the lines after the items
        display: flex;
        // align-center to have the lines in the middle (vertically)
        align-items: center;

        &:first-child {
            .btn {
                margin-left: -$wf-step-img-padding;
                padding-left: $wf-step-img-padding;
            }
        }

        &:last-child {
            // last child needs to have flex-grow 0, such that it keeps its minimum size -> right aligned
            flex-grow: 0;

            .btn {
                // keep the padding for a nicer looking hover effect
                margin-right: -$wf-step-img-padding;
                padding-right: $wf-step-img-padding;
            }
        }

        .btn {
            align-items: baseline;
            padding: calc($wf-step-img-padding * .5) $wf-step-img-padding;
            color: $body-color;
            white-space: nowrap;

            svg {
                top: auto;
                margin-right: $wf-step-img-padding;
                margin-bottom: $spacer-xs;
            }

            &:hover {
                background-color: $kw-gray-lightest;
            }

            &:disabled {
                background-color: transparent;
                border-color: transparent;
            }
        }

        // pseudo ::after element on all list-items except the last one.
        // This creates the lines in between the items: use width 100% to
        // span the whole flex item, use background-color and height 1px to emulate a hr
        &:not(:last-child)::after {
            background-color: $kw-gray-lighter;
            content: "";
            width: 100%;
            height: 1px;
            display: block;
            margin-left: 10px;
            margin-right: 10px;
        }

        .workflow-step-inactive {
            color: $kw-gray-lightest;
        }

        .workflow-step-active, .workflow-step-complete, .workflow-step-ready {
            color: $body-color;
        }

        .workflow-step-invalid {
            color: $warning;
        }
    }
}

kw-meta-panel-information {
    .fas {
        position: relative;
        margin: 0 .5rem 0 .25rem;
    }

    .information-label-key {
        color: $kw-gray;
    }
}

kw-workflow-buttons {
    @include spacer('pt-125');
    display: block;
}
