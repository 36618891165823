.table {
    margin-bottom: 0;

    tbody {
        td {
            color: $body-color;
        }
    }

    .total {
        background-color: $kw-gray-lightest;
    }
}
