.dropdown-menu {
    overflow-y: hidden;

    .dropdown-header {
        font-weight: bold;
    }

    .dropdown-item {
        @include border-radius($dropdown-inner-border-radius);
        display: flex;
        flex-direction: row;
        line-height: 1;
        font-family: $font-family-base;

        &.focus,
        &:focus {
            outline: none;
        }
    }
}

.dropdown-menu-scroll {
    max-height: 200px;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;

    a {
        margin-right: 1px;
    }
}

.dropdown {
    // in case, the dropdown button is not supposed to be pulled right,
    // the display of the dropdown must be inline-block,
    // so that it is not placed on the next row */
    display: inline-block;
}

.dropright {

    .dropdown-menu-toolbar {
        min-width: 0;
        padding: 0;
        position: static;
        float: right;
        &.show {
            display: flex;
        }

        .dropdown-item {
            padding: 0;
            width: auto;
            &:hover {
                background-color: transparent;
            }
        }

        .btn.btn-link {
            padding: .375rem $spacer-md;
            margin: 0;
            &:hover {
                background-color: $kw-info-bg;
                border-radius: 0;
            }
        }

        .fas, .far, .fal, .fad {
            padding: 0;
        }

        .dropdown-divider-vertical {
            border-left: 1px solid $kw-gray-lightest;
        }
    }
}
