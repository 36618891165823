//
// General
//

html {
    height: 100%;
}

html, body {
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
    min-height: 100%;
    background: $kw-application-bg;
    padding-bottom: 2rem;
}

body, input, button, select, optgroup, textarea {
    letter-spacing: .2px;
}

[role=main] {
    margin-left: calc(100vw - 100%);

    [role=navigation] {
        margin-left: calc(100% - 100vw);
    }
}

[role=navigation] {
    padding-left: calc(100vw - 100%);
}

.container-xxl {
    width: calc(100vw - 2.5rem);
}

//
// Headings
//

h1, .h1 {
    font-size: $h1-font-size;
    margin-top: $kw-heading1-margin-top;
    margin-bottom: $kw-heading1-margin-bottom;
}

h2, .h2 {
    font-size: $h2-font-size;
    margin-top: $kw-heading2-margin-top;
    margin-bottom: $kw-heading2-margin-bottom;
}

h3, .h3 {
    font-size: $h3-font-size;
    margin-top: $kw-heading3-margin-top;
    margin-bottom: $kw-heading3-margin-bottom;
}

h4, .h4 {
    font-size: $h4-font-size;
    line-height: $line-height-base;
    margin-top: $kw-heading4-margin-top;
    margin-bottom: $kw-heading4-margin-bottom;
}

.lead {
    font-size: $h2-font-size;
    color: $kw-gray;
}

//
// Text Elements
//

strong, b {
    font-weight: $font-weight-bold;
}

code {
    strong, b {
        font-family: $font-family-monospace;
    }
}

label, legend {
    font-size: $font-size-sm;
    color: $kw-gray;

    &.white {
        color: $white;
    }

    &.below {
        display: block;
        margin-top: $spacer-xs;
        margin-bottom: 0;
    }
}

// deprecated, will be removed by cleanup story #14989
p, .p {
    &.gray {
        color: $kw-gray;
    }
}

// TODO #22280: Check if still needed or if we can get rid of the class
.text-muted {
    color: $kw-gray-light !important;
}

.kw-text-gray {
    color: $kw-gray;
}

//
// Lists
//

ul.ul, ol.ol {
    margin-bottom: 0;
    margin-left: $list-spacer-left + $list-spacer-inner;
    padding-left: 0;
    position: relative;

    > li {
        margin: 0;
        list-style: none;
        padding-bottom: $spacer-xs;

        &:last-child {
            padding-bottom: 1rem;
        }
    }
}

ul.ul {

    > li {

        &::before {
            content: "\2022";
            font-size: $h2-font-size;
            line-height: 1rem;
            color: $kw-gray-light;
            position: absolute;
            left: -$list-spacer-inner;
            width: $list-spacer-inner;
        }
    }
}

ol.ol {

    counter-reset: section;

    > li {

        &::before {
            content: counter(section) ".";
            counter-increment: section;
            position: absolute;
            color: $kw-gray;
            left: -$list-spacer-inner;
            width: $list-spacer-inner;
        }
    }
}

ol, ul {
    &.list-no-margin-left {
        margin-left: $list-spacer-inner;
    }
}
