.alert-dismissible .close {
    margin: 0;
}

.alert {
    .btn-link {
        color: inherit;
        padding: $spacer-xs;

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active,
        &:focus {
            text-decoration: none;
            border-color: transparent;
            background-color: adjust-color($kw-gray-light, $alpha: -.8);
        }
    }


    &.alert-info {
        background-color: $kw-gray-lightest;
    }
}

ngb-alert.alert .btn-close {
    @extend .btn-link;
    @extend .kw-icon-btn;
    padding: 0;
    margin: 1rem;
}
