// Forms
//
// Legacy form styles that will eventually be replaced

// use hr to create horizontal dividers in forms (TODO: In Zukunft nicht mehr verwenden)
hr {
    border-top: 1px solid $kw-gray-lighter;
    margin: 0;
    opacity: 1;
}

// restrict height of legacy form labels (those to the left of textareas) to shrink clickable area with magic auto-focus
// see #12602 for details; new forms should not even use this class/layout anymore (labels above the textarea)
label.col-form-label {
    @warn "Deprecated. See deprecated.md of angular docs (kwgitlab.kwsoft.local/docs/angular).";
    height: max-content;
}

// hide html5 spinner buttons on number input
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; //NOSONAR scss:vendor-prefix: number spinners only appear in webkit-based browsers
    margin: 0;
}

// IE copies the white-space property for textarea from it's immediate parent, for whatever reason...
// we always want to use pre-wrap, to allow structured text in textarea fields.
textarea {
    white-space: pre-wrap;
}

.form-horizontal {

    .col-form-label {
        font-size: $font-size-base;

        &.text-end {
            text-align: right;
        }
    }

    .form-total {
        border-top: 2px solid $kw-gray-lighter;
        padding-top: $spacer-xs;
        margin-left: 0;
        margin-right: 0;
        font-weight: $font-weight-bold;

        .col-form-label {
            padding-left: 0;
            font-weight: $font-weight-bold;
            color: $body-color;
        }
    }

    // Der zieht, ich weiss aber nicht wo eingesetzt.
    // padding-right war mit @padding-base-horizontal (Wert = 12px) definiert,
    // die Variable gibt es bei Bootstrap 4 nicht mehr.
    .form-control-plaintext.number {
        text-align: right;
        padding-right: 12px;
    }

    textarea {
        resize: vertical;
    }
}

