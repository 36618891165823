
kw-breadcrumbs {
    .breadcrumbs {
        @include spacer('mb-50');

        .breadcrumbs-separator {
            @include spacer('px-50');
        }
    }
}
