.kw-no-transition {
    transition: none;
}

.collapseable {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: all .5s ease-out;
}

.collapseable.collapseAnimation {
    visibility: visible;
    max-height: 2000px;
    transition: all .5s ease-in;
}

.scale-up-ver-top {
    animation: scale-up-ver-top 100ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-ver-top {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
    }
}
