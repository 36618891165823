//
// Check/radio
//

.form-check-label {
    font-size: $font-size-base;
}

.form-check-input {
    cursor: $form-check-label-cursor;
    &:hover {
        background-color: darken($kw-gray-lighter, 7.5%);
    }
}

.form-switch .form-check-input:hover {
    border-color: $form-switch-focus-color;
}
