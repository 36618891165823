// Progress
//
// This file contains all custom progressbar related style definitions

// Inline progress to be placed underneath a button for example
.progress-inline {
    height: $kw-progress-bar-inline-height;
    margin-top: $kw-progress-bar-top-margin;
    margin-bottom: $kw-progress-bar-bottom-margin;
}

.progress-placeholder {
    display: block;
    margin-top: $kw-progress-bar-top-margin;
    height: $kw-progress-bar-inline-height;
}
