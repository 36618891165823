// The 'kw-readonly' style class is added to all elements when they are disabled by the kw-readonly directive
:not(textarea).kw-readonly {
    pointer-events: none;

    a {
        // For links, this style is what disables them. Additional appearance changes can be added
        // note that for example for uib-dropdown elements, the 'kwReadonlyMutatingControl' directive
        // must be added on the parent <div>, thus this style is applied to 'a.kw-readonly-disabled' directly
        // as well as to '*.kw-readonly-disabled a' (all <a> inside a .kw-readonly-disabled block)
        pointer-events: none;
    }
}

textarea.kw-readonly {
    @include box-shadow(none);

    &.btn-link {
        color: $btn-link-disabled-color;
    }
}
