// Cards

.card {
    margin-top: $spacer-md;

    .card-body {
        padding: $spacer-lg;
    }
}

.card-header {
    padding: 0;

    div[class^="col-"] {
        margin: 0 0 $spacer-md;
    }
}

.card-transparent {
    border: 1px solid $kw-gray-lighter;
    border-radius: $border-radius;
    padding: $spacer-sm;
}
