// BS Functions
@import "bootstrap/scss/functions";

// KW Variable Overwrites
@import "variables";

// BS Variables
@import "bootstrap/scss/variables";

// BS Configuration
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// BS Layout & Components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";

// BS Helpers
@import "bootstrap/scss/helpers";

// BS Utilities
@import "bootstrap/scss/utilities/api";

// KW Configuration
@import "functions";
@import "mixins";
@import "utilities/spacing";
@import "utilities/text";

// KW Layout & Components
@import "fonts";
@import "type";
@import "forms";
@import "buttons";
@import "dropdown";
@import "input-group";
@import "modal";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "pagination";
@import "progress";
@import "list-group";
@import "close";
@import "transitions";
@import "alert";
@import "tables";
@import "tooltip";

@import "../scss-legacy/forms";
@import "../scss-legacy/table";

// KW Helpers
@import "helpers/color-bg";

// Application loading indicator
//
// style for initial static content that is shown while angular app is loading

.app-loading-indicator {
    width: 100vw;
    height: 100vh;
    display: flex;

    > div {
        text-align: center;
        margin: auto;

        > h2 {
            @extend .text-muted;
        }
    }
}
