// #15553 TODO cleanup unnecessary css definitions
.accordion .accordion-item {
    border-style: solid;
    border-bottom-width: $border-width;
    border-right: none;
    border-left: none;
    border-color: $kw-gray-lighter;
    border-radius: 0;
    box-shadow: none;

    margin: 0;
    padding: 0;

    &:first-of-type {
        margin-top: $spacer-lg;
    }

    .accordion-header {
        display: flex;
        margin: $spacer-md 0;
        padding: 0;
        border: 0;
        align-items: center;

        .row {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }

        div[class^="col-"] {
            margin: 0;
        }

        .toggle {
            width: 4rem;

            .btn {
                line-height: 1;
                font-size: 1.25rem;
                margin-left: 0;
            }
        }
    }

    .accordion-body {
        padding: $spacer-sm 0 $spacer-md $spacer-xl;
        border: 0;
    }

    label {
        margin-top: 1rem;
    }
}
