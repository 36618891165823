.kw-search-box {
    position: relative;
    font-family: $font-family-base;
    display: inline-flex;
    width: 100%;

    input {
        padding-left: $spacer-lg;
        padding-right: $spacer-xl + $spacer-sm;
    }
}

.kw-search-box-icon, .kw-search-box-action {
    color: $kw-gray-light;
    position: absolute;
    width: $spacer-lg;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .fas, .far, .fal, .fad {
        font-size: $font-size-base;
        color: $input-placeholder-color;
    }
}

.kw-search-box-icon {
    width: $spacer-lg;
}

.kw-search-box-action {
    right: 0;
    width: $spacer-xl + $spacer-sm;

    .btn-link {
        font-size: $font-size-sm;
        padding-top: 1px;
    }

    .btn-link:not(:disabled):not(.disabled):hover  {
        background: transparent;
    }
}
