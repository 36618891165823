//
// List group
//

.list-group.list-group-flush {

    .list-group-item {
        padding: $spacer-md 0 $spacer-sm;

        @each $breakpoint in map-keys($grid-breakpoints) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @for $i from 1 through $grid-columns {

                @for $j from 1 through $grid-columns {

                    .col#{$infix}-#{$i} .col#{$infix}-#{$j} {
                        margin-top: auto;
                        margin-bottom: $spacer-sm;
                    }
                }
            }
        }

        &:first-child{
            border-top: 1px solid $kw-gray-lighter;
        }

        &:last-child {
            border-bottom: 1px solid $kw-gray-lighter;
        }
    }
}
