//
// Tab Navigation
//

.nav-tabs {
    // Active tabs disable the pointer events on the tab because their anchor tag is disabled.
    // Therefore, if a button is used inside an active tab,
    // the pointer events need to be turned on for this button to enable clicking.
    .disabled .tab-button {
        pointer-events: auto;
    }

    .nav-link {
        @include spacer('me-25');
        @include spacer("px-100");
        @include spacer("py-50");

        cursor: pointer;

        &:hover,
        &:focus {
            isolation: auto;
        }

        &.active {
            color: $body-color;
        }

        &:not(.active) {
            color: $kw-gray;
            background-color: $kw-gray-lightest;
            border-bottom: none;
        }

        &:hover:not(.active) {
            color: $body-color;
            background-color: $kw-gray-lighter;
        }
    }

    &.navbar {
        // A navigation-like tab bar with a background
        background-color: $kw-gray-lighter;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
        justify-content: left;

        .nav-link {
            @include spacer('me-0');
            @include spacer("px-150");
            @include spacer("py-75");
            border: none;

            &:not(.active), &:hover:not(.active) {
                background-color: transparent;
            }
        }
    }

    &.kw-underlined {
        // navigation-like tabs with bottom border on active tabs
        background-color: transparent;

        .nav-link {
            @include spacer('me-0');
            @include spacer("px-150");
            @include spacer("py-0");

            border: none;
            border-bottom: $kw-nav-underline-active-border-width solid transparent;
            height: $kw-nav-underline-tab-height;
            display: flex;
            align-items: center;

            &, &:hover:not(.active), &:not(.active) {
                background-color: transparent;
            }

            &.active {
                border-bottom: $kw-nav-underline-active-border-width solid $nav-underline-link-active-color;
            }
        }
    }
}

.tabs-underlined {
    // Angular JS only: Used in the cockpit as a navigation without backgrounds or tab borders
    @extend .kw-underlined;

    .nav.nav-tabs {
        border-bottom: none;

        .nav-link {
            border-bottom-width: 2px;

            &.active {
                border-bottom-width: 2px;
            }
        }
    }
}
