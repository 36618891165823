@import "quill/dist/quill.snow.css";

kw-rich-text-editor {
    &.kw-readonly, &.readonly-mode {
        cursor: default;
        pointer-events: auto;

    }

    &.kw-readonly.kw-rich-text-editor, .kw-rich-text-editor.readonly-mode {
        .ql-toolbar {
            display: none;
        }

        .ql-container {
            background-color: $kw-gray-lighter;
            border-radius: $border-radius; // compensate for missing toolbar
        }
    }

    .ql-toolbar {
        border-radius: $border-radius $border-radius 0 0;
    }

    .ql-container {
        height: 25rem;
        border-radius: 0 0 $border-radius $border-radius;

        &.ql-snow .ql-editor { // overwrite theme-specific styles of defined theme "snow"
            padding: $spacer-md $spacer-lg;

            font-size: 0.9375rem;

            h1 {
                font-size: 2rem;
            }

            h2 {
                font-size: 1.5rem;
            }

            h3 {
                font-size: 1.125rem;
            }
        }
    }

    .ql-toolbar, .ql-container {
        &.ql-snow { // overwrite theme-specific styles of defined theme "snow"
            font-family: $font-family-base;
            border-color: $kw-gray-lighter;
        }
    }

}

.has-error kw-rich-text-editor {
    .ql-toolbar.ql-snow, .ql-container.ql-snow {
        border-color: $danger;
    }
}
